import React, { useState, useEffect, lazy, Suspense, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';

import { HeroImageRight } from '../components/General/HeroImageRight';
import { HeroVideo } from '../components/General/HeroVideo';
import { Hero } from '../components/General/Hero';
import { Resources } from '../components/General/Resources';
import { WaveUpSVG } from '../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../components/SEO';
import { Testimonials } from '../components/Testimonials';
import { CustomerLogos } from '../components/CustomerLogos';
// import { Form } from '../components/ContactForm/Form';
import FeatureThumbnailContext from '../context/FeatureThumbnailContext';
import { ThumbnailFeatureModal } from '../components/General/ThumbnailFeatureModal';
import { WhyPestpacBody } from '../components/WhyPestpac/WhyPestpacBody';

const Form = loadable(() => import('../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	whyPPheroFix: {
		height: "6rem",

		[theme.breakpoints.down('sm')]: {
			height: "2rem",
		},
	},
	background: {
		background: theme.white,
	},
	heroVideo: {
		marginBottom: '5rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '3rem',
		},
	},
	formWaveDown: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-4rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-3rem',
		},
	},
	formWaveUp: {
		marginBottom: '-8rem',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '-10rem',
		},
	},
	formCont: {
		padding: '16rem 0',

		[theme.breakpoints.down('xs')]: {
			padding: '6rem 0 8rem 0',
		},
	},
}));



export default function WhyPestpac({ data, location }) {
	const classes = useStyles();
	const whyPestpac = data.whyPestpac.edges[0].node;

	const { modalOpen, setModalOpen, selectedThumbnail } = useContext(
		FeatureThumbnailContext
	);

	const {
		_id,
		metaTitle,
		metaDescription,
		marketoId,
    pardotUrl,
		contactForm,
		hero,
		heroImage,
		heroVideo,
		heroAlignCenter,
		formBgImage,
		resourceTitle,
		resources,
		_rawResourceBody,
		testimonialTitle,
		testimonial,
		customerLogos,
		customerLogosHeader,
		testimonialBackground,
		_rawIntroText,
		highlightCards,
		softwareFeatureListHeader,
		softwareFeatures,
	} = whyPestpac;
	
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
				<div
				className={classes.whyPPheroFix}
					
					
				></div>
				<HeroImageRight
					_id={_id}
					hero={hero}
					heroImage={heroImage?.asset?.gatsbyImageData}
					feature
				/>
			

			{/* <WaveUpSVG height='213' width='100%' fill='#fff' /> */}
			<WaveDownSVG height='213' width='100%' fill='#FFFFFF' />
			<div
				style={{ background: '#fff', position: 'relative'}}
			>
			<WhyPestpacBody
				// style={{position: 'relative', zIndex: '9'}}
				intro={_rawIntroText}
				highlightCards={highlightCards}
				softwareFeatureListHeader={softwareFeatureListHeader}
				softwareFeatures={softwareFeatures}
			/>
			</div>
			{/* <div
				className={classes.testimonialBackground}
				style={{
					backgroundImage: `url(${testimonialBackground?.asset.gatsbyImageData.images.fallback.src})`,
				}}
			/> */}
			{/* <WaveUpSVG fill='#FFF' /> */}
			<div className={classes.testimonialContainer}>
				<Testimonials
					header={testimonialTitle}
					testimonials={testimonial}
					// formRef={formRef}
					// background
				/>
			</div>
			<Container>
				<CustomerLogos
					customerLogosArray={customerLogos}
					customerLogosHeader={customerLogosHeader}
				/>
			</Container>
			<div>
				<WaveDownSVG height='213' width='100%' fill={'#FFFFFF'} />
			</div>

			<div
				className={classes.formCont}
				style={{
					backgroundImage: `url(${formBgImage?.asset?.gatsbyImageData?.images.fallback.src})`,
					backgroundSize: 'cover',
				}}>
				<Form
					formId={marketoId}
          pardotUrl={pardotUrl}
					contactForm={contactForm}
					privacy
					modal={false}
					location={location}
				/>
			</div>
			<WaveUpSVG height='213' width='100%' fill='#FFFFFF' />
			<Container>
				<Resources
					header={resourceTitle}
					resources={resources}
					subheader={_rawResourceBody}
				/>
			</Container>
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			<ThumbnailFeatureModal
				open={modalOpen}
				setFeatureModalOpen={setModalOpen}
				wistiaLink={selectedThumbnail}
				feature
			/>
		</>
	);
}

export const query = graphql`
	query WhyPestpacPageQuery {
		whyPestpac: allSanityWhyPestpac {
			edges {
				node {
          _id
					title
					metaTitle
					metaDescription
					hero {
            _rawContent
						backgroundImage {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaButtonText
						ctaButtonLink
						internalLink
					}
					heroImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					heroVideo
					heroAlignCenter
					_rawIntroText
					highlightCards {
						title
						icon
						header
						accentColor {
							hexValue
						}
						_rawContent
            ctaText
						ctaLink
					}
					softwareFeatureListHeader
					softwareFeatures {
						title
						comparisonHeader
						featureListCta
						logos {
							asset {
								gatsbyImageData
							}
						}
						alternateTextHeaders
						features {
							featureTitle
							featureDescription
							colOne
							colTwo
							colThree
						}
						expandedListHeader
						expandedListSubheader
						expandText
						hideText
						softwareLogoAndDescription {
							softwareComparisonTitle
							logos {
								asset {
									gatsbyImageData(placeholder: BLURRED, height: 85)
								}
							}
						}
					}
					testimonialTitle
					testimonial {
						title
						header
						testimonialLogo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						company
						testimonialText
						nameAndTitle
						videoVariant
						image {
							asset {
								gatsbyImageData(
									fit: FILLMAX
									height: 450
									width: 775
									placeholder: BLURRED
								)
							}
						}
					}
					testimonialBackground {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					resourceTitle
					_rawResourceBody
					resources {
						title
						blurb
						image {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
						ctaText
						ctaLink
					}
					formBgImage {
						asset {
							gatsbyImageData(placeholder: BLURRED)
						}
					}
					contactForm {
						header
						privacyPolicy
					}
					marketoId
          pardotUrl
					customerLogosHeader
					customerLogos {
						logo {
							asset {
								gatsbyImageData(placeholder: BLURRED)
							}
						}
					}
				}
			}
		}
	}
`;
